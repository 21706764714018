import { Typography, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { CHARGING_SOLUTIONS } from '../../../data/analytics';

import chargingHeader from '../../../images/Delivery/chargingHeader.png';
import chargingHome from '../../../images/Delivery/chargingHome.png';
import chargingBackground from '../../../images/Delivery/chargingBackground.png';
import chargingPublic from '../../../images/Delivery/chargingPublic.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import TextOnBackground from '../../../components/textOnBackground/TextOnBackground.jsx';

const ChargingSolutions = () => {
  return (
    <Trackable
      interfaceId={CHARGING_SOLUTIONS.INTERFACE_ID}
      loadId={CHARGING_SOLUTIONS.ON_LOAD}
    >
      <PageHeader image={chargingHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Final Mile Delivery
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Charging Solutions
        </Typography>
      </PageHeader>
      <SideBySide
        image={chargingHome}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={15}
        mobileDirection="column-reverse"
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h2Light' : 'h1Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 4 }}
        >
          Home Chargers
        </Typography>
        <Typography variant="h2Light" gutterBottom>
          With our ever expanding all-electric delivery fleet, our drivers need
          access to affordable and reliable charging solutions.
        </Typography>
        <Typography variant="h5Light">
          DPD has partnered with PodPoint to provide home chargers for our
          delivery drivers and contributes £350 towards each driver's home
          charger, which then belongs to the driver. After all, we want to make
          switching to an electric vehicle as easy as possible for our delivery
          drivers.
        </Typography>
      </SideBySide>
      <TextOnBackground
        background={chargingBackground}
        textSide="right"
        verticalPadding={25}
        mobileVerticalPadding={4}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h2Light' : 'h1Light'
          }
          color="#FFF"
          gutterBottom
        >
          Depot Charging
        </Typography>
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color="#FFF"
        >
          PodPoint chargers have been installed at most of our sites for any
          drivers that struggle to find suitable charging solutions.
        </Typography>
      </TextOnBackground>
      <SideBySide
        image={chargingPublic}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={20}
        mobileDirection="column-reverse"
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h2Light' : 'h1Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 4 }}
        >
          Public Charging Solutions
        </Typography>
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          gutterBottom
        >
          Through various partnerships we make over 20,000 public charge points
          available to our drivers.
        </Typography>
        <Typography variant="h5Light">
          Through chargepoint operators our drivers can access the best deals
          with low prices and membership incentives.
        </Typography>
      </SideBySide>
    </Trackable>
  );
};

export default ChargingSolutions;
